import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import UserAreaMenu from "../components/userAreaMenu"
import StoreOrders from "../components/storeOrders"
import Form from "../components/common/form"
import ReactSelect from "react-select"
import { getCurrentUser } from "../services/authService"
import { getItems } from "../services/collectionServices"
import { anonymousUser, emptyStore } from "../config.json"
import {
  apiStore2SelectStores,
  getLocalCart,
  setLocalCart,
} from "../utils/functions"
import SpinnerLoading from "../components/common/spinnerLoading"
import { goToAnchor, configureAnchors } from "react-scrollable-anchor"

const drawStoreLabel = function(storeSelected) {
  if (
    storeSelected &&
    storeSelected.obj &&
    storeSelected.obj.relationships &&
    storeSelected.id > 0
  ) {
    const address = storeSelected.obj.relationships.address.data.attributes
    return (
      <>
        {storeSelected.value} [{storeSelected.obj.attributes.code}]-{" "}
        <span
          style={{ fontSize: "0.8rem", fontStyle: "italic", color: "#b1b1b1" }}
        >
          {address.address_line} {address.postal_code} {address.city} (
          {address.state}) {address.country}
        </span>
      </>
    )
  } else {
    return ""
  }
}

class StoricoOrdini extends Form {
  state = {
    data: { stores: 0 },
    errors: [],
    stores: [],
    orders: { data: [] },
    user: { ...anonymousUser },
    storeSelected: {},
    userIsClient: true,
    storesLoading: true,
    ordersLoading: false,
    storeSearchText: "",
    timeout: null,
    pagination: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
  }

  async componentDidMount() {
    configureAnchors({ scrollDuration: 2000 })
    const user = await getCurrentUser()
    this.setState({ user })
    if (user.id === 0) {
      window.location = "/login"
    }
    let userIsClient = true
    try {
      userIsClient = user.relationships.roles.data[0].id === 3
    } catch (error) {}

    // Recupero il carrello
    let cart = getLocalCart()

    // Recupero gli store
    let storeSelected = {}
    let stores = [{ ...emptyStore }]
    stores = await getItems("stores")
    stores = apiStore2SelectStores(stores.data)

    if (cart.store.id > 0) {
      storeSelected = cart.store
      this.loadOrders(storeSelected)
    } else if (stores.length === 1) {
      storeSelected = { ...stores[0] }
      this.loadOrders(storeSelected)
    }

    this.setState({ stores, storeSelected, userIsClient, storesLoading: false })
  }

  resetOrders = async () => {
    this.setState({
      orders: { data: [] },
    })
  }

  loadOrders = async (store, page) => {
    if (page === undefined) {
      page = 1
    }
    this.setState({ storeSelected: store, ordersLoading: true })
    const nextOrders = await getItems(
      "orders/get-history/" + store.id + "?page=" + page
    )
    const { orders } = this.state
    nextOrders.data = [...orders.data, ...nextOrders.data]
    this.setState(
      {
        orders: nextOrders,
        pagination: nextOrders.meta,
        storeSelected: store,
        storeSearchText: "",
        ordersLoading: false,
      },
      () => {
        let pagination = nextOrders.meta
        if (pagination.current_page > 1) {
          let anchor =
            "anchor" + (nextOrders.data.length - (pagination.per_page + 1))
          goToAnchor(anchor)
        }
      }
    )
  }

  handleStoreClick = async store => {
    await this.resetOrders()
    await this.loadOrders(store)
  }

  handleSelectStoreByAgent = async (e, store) => {
    e.preventDefault()
    await this.resetOrders()
    await this.loadOrders(store)

    // Aggiorno il cart
    let cart = getLocalCart()
    cart.store = store
    setLocalCart(cart)
  }

  searchStoresByText = async query => {
    if (query.trim().length < 3) {
      return
    }

    this.setState({ storesLoading: true, storeSelected: { ...emptyStore } })
    let stores = await getItems("stores/search?filter[query]=" + query)
    stores = apiStore2SelectStores(stores.data)
    this.setState({ storesLoading: false, stores })
  }

  handleStoreSearchChange = e => {
    const query = e.target.value
    this.setState({ storeSearchText: query })

    clearTimeout(this.state.timeout)
    this.setState({
      timeout: setTimeout(
        function() {
          this.searchStoresByText(query)
        }.bind(this),
        3000
      ),
    })
  }

  handlePaginationMove = page => {
    const { storeSelected } = this.state
    this.loadOrders(storeSelected, page)
  }

  handleSeeMore = () => {
    const { pagination, storeSelected } = this.state
    if (pagination.current_page < pagination.last_page) {
      pagination.current_page++
    }
    this.setState({ pagination }, () => {
      this.loadOrders(storeSelected, pagination.current_page)
    })
  }

  render() {
    const {
      orders,
      stores,
      user,
      storeSelected,
      userIsClient,
      storesLoading,
      storeSearchText,
      ordersLoading,
      pagination,
    } = this.state
    return (
      <Layout>
        <SEO
          title="Storico Ordini"
          keywords={[`vision care`]}
          description="Storico Ordini"
        />

        {user.id > 0 && (
          <div className="container">
            <div className="row">
              <div className="col-12 pt-3">
                <h1 className={"page-title"}>Area Clienti</h1>

                <div className={"row"}>
                  {/*  LEFT */}
                  <div className="col-12">
                    <UserAreaMenu menuItemSelected="Storico ordini" />
                  </div>

                  {/*  CENTER */}
                  <div className="col-12">
                    <h2 className={"customers-area-title"}>Storico ordini</h2>

                    {storesLoading && (
                      <div className="mt-5">
                        <SpinnerLoading />
                      </div>
                    )}

                    {!storesLoading && (
                      <div className="l">
                        <label htmlFor="stores">
                          Seleziona un cliente o un punto vendita
                        </label>

                        {!storesLoading && userIsClient && (
                          <ReactSelect
                            id="stores"
                            options={stores}
                            onChange={this.handleStoreClick}
                            value={storeSelected}
                          />
                        )}
                        {!userIsClient && (
                          <input
                            type="text"
                            className="form-control"
                            id="products"
                            placeholder="inizia a digitare il nome del cliente..."
                            onChange={this.handleStoreSearchChange}
                            value={storeSearchText}
                          />
                        )}

                        {!storesLoading &&
                          storeSelected.id === 0 &&
                          storeSearchText.trim().length >= 3 && (
                            <div className="row">
                              <div className="col-12">
                                <table>
                                  <tbody>
                                    {stores.map(store => (
                                      <tr
                                        key={store.id}
                                        className="c-pointer userAreaMenuItem"
                                        onClick={e =>
                                          this.handleSelectStoreByAgent(
                                            e,
                                            store
                                          )
                                        }
                                      >
                                        <td>{drawStoreLabel(store)}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          )}
                      </div>
                    )}

                    {!storesLoading &&
                      stores.length == 0 &&
                      userIsClient &&
                      storeSearchText.length > 3 && (
                        <span className="text-danger">
                          Non hai alcun punto vendita associato a questo
                          account. Contatta il servizio clienti per maggiori
                          informazioni!
                        </span>
                      )}

                    {!storesLoading &&
                      stores.length > 0 &&
                      !ordersLoading &&
                      storeSelected.id > 0 && (
                        <div className="mt-4">
                          <p className="mb-0">
                            Cliente selezionato:{" "}
                            <strong>{storeSelected.fullName}</strong>
                          </p>
                          <hr />
                        </div>
                      )}

                    {!storesLoading &&
                      stores.length > 0 &&
                      !ordersLoading &&
                      storeSelected.id > 0 &&
                      orders.data.length === 0 && (
                        <div className="mt-1">
                          <p className="text-danger">
                            Non ci sono ordini per questo cliente
                          </p>
                        </div>
                      )}

                    {!storesLoading &&
                      stores.length > 0 &&
                      orders.data.length > 0 && (
                        <div>
                          <StoreOrders
                            orders={orders}
                            userIsClient={userIsClient}
                            onPaginationClick={this.handlePaginationMove}
                            onSeeMoreClick={this.handleSeeMore}
                            ordersLoading={ordersLoading}
                            isLastPage={
                              pagination.current_page >= pagination.last_page
                            }
                          />
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
            {!storesLoading && ordersLoading && (
              <div className="mt-5">
                <SpinnerLoading />
              </div>
            )}
          </div>
        )}
      </Layout>
    )
  }
}

export default StoricoOrdini
