import React, { Component } from "react"
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion"
import {
  formatCurrency,
  dateTimeToDate,
  dateTimeToHour,
} from "../utils/functions"
import Pagination from "./common/pagination"
import { FaAngleDown } from "react-icons/fa"
import "react-accessible-accordion/dist/fancy-example.css"
import "moment/locale/it"
import moment from "moment"

class storeOrders extends Component {
  state = {
    pagination: {
      current_page: 0,
      from: 0,
      last_page: 0,
      path: "",
      per_page: 0,
      to: 0,
      total: 0,
    },
  }

  componentDidMount() {
    if (this.props.orders) {
      if (this.props.orders.meta) {
        this.setState({ pagination: this.props.orders.meta })
      }
    }
  }

  checkIfProductExists = item => {
    if (!item) {
      return false
    }
    if (!item.relationships.hasOwnProperty("product")) {
      return false
    }
    if (!item.relationships.product.hasOwnProperty("data")) {
      return false
    }
    if (!item.hasOwnProperty("type")) {
      return false
    }
    return true
  }

  orderHasProducts = order => {
    let flag = false
    for (let i = 0; i < order.relationships.rows.data.length; i++) {
      flag = this.checkIfProductExists(order.relationships.rows.data[i])
    }
    return flag
  }

  render() {
    const { orders, userIsClient } = this.props
    const { pagination } = this.state
    const object = this
    return (
      <div>
        <div className="mt-0 orders-history-accordion">
          {/* <div className="row">
                    <div className="col d-flex justify-content-end">
                        <Pagination pagination={ pagination }  onPageChange={this.props.onPaginationClick}/>
                    </div>
                </div>*/}

          <Accordion className="mb-3">
            {orders.data.map(function(order, indice) {
              const creationDate = moment(
                order.attributes.created_at,
                "YYYY-MM-DDTHH:mm:ss.SSSSSSZ"
              )

              return (
                <AccordionItem key={indice} id={"anchor" + indice}>
                  <AccordionItemTitle>
                    <div className="row">
                      <div className="col-1">
                        <strong>{order.history_data.document_type}</strong>
                      </div>
                      <div className="col-1">
                        <strong>{order.history_data.document_number}</strong>
                      </div>
                      <div className="col-6">
                        Ordine del {creationDate.format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </AccordionItemTitle>
                  <AccordionItemBody>
                    {!object.orderHasProducts(order) && (
                      <div className="text-danger  b mb-5">
                        Questo ordine non contiene prodotti!
                      </div>
                    )}
                    {object.orderHasProducts(order) && (
                      <table>
                        <thead>
                          <tr>
                            <th>Codice</th>
                            <th>Descrizione</th>
                            {!userIsClient && <th>Tipo</th>}
                            <th className="r">Quantità</th>
                            <th className="r">Prezzo</th>
                          </tr>
                        </thead>
                        <tbody>
                          {order.relationships.rows.data.map(
                            (item, index) =>
                              object.checkIfProductExists(item) && (
                                <tr key={index}>
                                  <td>
                                    {
                                      item.relationships.product.data.attributes
                                        .code
                                    }
                                  </td>
                                  <td>
                                    {
                                      item.relationships.product.data.attributes
                                        .description
                                    }
                                  </td>
                                  {!userIsClient && (
                                    <td>{item.attributes.type}</td>
                                  )}
                                  <td className="r">
                                    {item.attributes.quantity}
                                  </td>
                                  <td className="r">
                                    {formatCurrency(item.history_data.price)}
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    )}
                  </AccordionItemBody>
                </AccordionItem>
              )
            })}
          </Accordion>
          <div className="row">
            <div className="col-12 text-center">
              {/* <Pagination pagination={ pagination }  onPageChange={this.props.onPaginationClick}/> */}
              {!this.props.ordersLoading && !this.props.isLastPage && (
                <div
                  className="showMoreButton"
                  onClick={this.props.onSeeMoreClick}
                >
                  <FaAngleDown /> vedi altro <FaAngleDown />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default storeOrders
